/* eslint-disable no-undef, react/prop-types, react/no-danger, jsx-a11y/alt-text */
import { graphql, Link, navigate } from 'gatsby';
import React, { Component } from 'react';
import _ from 'lodash';
import { SRLWrapper } from 'simple-react-lightbox';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import Carousel from 'nuka-carousel';
import getCategoryPlaceholderImage from './functions/getCategoryPlaceholderImage';
import PageTransition from '../components/PageTransition/index';
import { shorten } from '../utils/shorten';
import { exBarNav, exBarItem, exBarContent, exBarLink, exBarBar, navBasic, barHoverDark } from '../style/mouseOverUnderline';
import { breadCrumb, breadcrumbFix, inline } from '../style/breadCrumb';
import { Box, BoxVisible, Flex } from '../style/basicStyle';
import colors from '../utils/colors';

const NukaCarousel = ({ imageArray }) => {
  const imageArrayParsed = imageArray.map((imageRoot) => imageRoot.node && imageRoot.node.childImageSharp && imageRoot.node.childImageSharp.gatsbyImageDataFullWidth);
  return (
    <Carousel wrapAround>
      {imageArrayParsed.map((childImageSharp, index) => {
        const key = `${index}${childImageSharp.images.fallback.src}`;
        return <SRLWrapper><GatsbyImage alt={key} key={key} image={childImageSharp} /></SRLWrapper>;
      })}
    </Carousel>
  );
};

class NewsTemplate extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { props } = this;
    props.toggleNavDark({ navBarDark: true });
  }

  render() {
    const { props } = this;
    const { data } = props;
    const { articleMarkdown: post } = data;
    const { group: newsCategoryImages } = data.newsCategoryImages;
    const title = shorten({ maxchars: 25, content: post.frontmatter.title });
    const articleCategory = post.fields && post.fields.articleCategory && post.fields.articleCategory[0];
    const imageArrayOriginal = data.newsArticleImages && data.newsArticleImages.edges && _.cloneDeep(data.newsArticleImages.edges);
    let imageArray = imageArrayOriginal.sort((a, b) => b.node.name - a.node.name); // sort alphabetically
    let bannerImage;
    const bannerImageIndex = imageArray.findIndex(imageObj => {
      return imageObj.node && imageObj.node.name === 'banner'; // get banner image index from image array.
    });
    if (bannerImageIndex > -1) {
      bannerImage = imageArray[bannerImageIndex]; // set bannerImage variable if we found a banner image
      imageArray.splice(bannerImageIndex, 1); // remove banner image from image array
    }
    imageArray = imageArray && imageArray.filter(imageObj => imageObj.node && imageObj.node.name !== 'thumbnail'); // exclude the thumbnail image used in the news index page
    const articleDefaultImage = imageArray && ((bannerImage && bannerImage.node) || (imageArray[0] && imageArray.shift().node));
    const articleCategoryImage = articleCategory && getCategoryPlaceholderImage({ newsCategoryImages, articleCategory });
    const { childImageSharp } = articleDefaultImage || articleCategoryImage;
    if (props.errors) {
      props.errors.forEach(err => console.log('newsPost error rendering: ', err));
      throw new Error(props.errors[0] && props.errors[0].message);
    }
    /* end determine if default page */
    return (
      <PageTransition location={props.location}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>Florida Bulldogs News -{post.frontmatter.title}</title>
        </Helmet>
        <Box width={[1]} p={[1]} bg={colors.primary}>
          <Flex css={breadCrumb} wrap={['nowrap']}>
            <Box m={[0]} width={[1, 1, 1]} p={[1, 2]}>
              <div css={[exBarItem, breadcrumbFix]}>
                <div css={[exBarContent, inline]}>News </div>
                <Link to="/news" css={exBarLink}>
                  <span css={exBarBar} />
                </Link>
              </div>
              &gt;
              <div css={[exBarItem, breadcrumbFix]}>
                <div css={[exBarContent, inline]}>{articleCategory} </div>
                <Link to={`/news/${articleCategory.toLowerCase()}`} css={exBarLink}>
                  <span css={exBarBar} />
                </Link>
              </div>
              &gt; {title}
            </Box>
          </Flex>
          <Box width={[9 / 10, 7 / 10]} m={['1rem auto 0 auto', '1rem auto 0 auto', '1rem auto 0 auto']}>
            <Flex wrap={['wrap']}>
              <Box fontSize={[4, 4, 5, 6]}>
                <Box width={[1]} p={[1]}>
                  <Box>
                    <SRLWrapper>
                      <GatsbyImage image={childImageSharp && childImageSharp.gatsbyImageDataFullWidth} alt="news Image" />
                    </SRLWrapper>
                  </Box>
                  {post.frontmatter.title}
                  <Box fontSize={[2, 2, 3, 3]} p={[1]}>
                    {post.fields && post.fields.articleCategory[0]}
                  </Box>
                  <Box fontSize={[2, 2, 3, 3]} p={[1]}>
                    {post.frontmatter.author && `${post.frontmatter.author} · `} {post.frontmatter.date}
                  </Box>
                </Box>
              </Box>
              <Box textAlign="left" width={[1]} px={[3, 3, 0]} color={colors.secondary}>
                <Box fontSize={[2, 3]} p={[1]}>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </Box>
                <Box fontSize={[2, 2, 3, 3]} p={[1]} my={[1]}>
                  {post.frontmatter.targeturltitle && post.frontmatter.targeturltitle}
                </Box>
                <Box fontSize={[2, 2, 3, 3]} p={[1]}>
                  {(post.frontmatter.targeturlname && post.frontmatter.targeturl.indexOf('://') > -1 && (
                    <a style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer" href={post.frontmatter.targeturl}>
                      {post.frontmatter.targeturlname}
                    </a>
                  )) || <Link style={{ textDecoration: 'underline' }} to={post.frontmatter.targeturl}>{post.frontmatter.targeturlname}</Link>}
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box textAlign="center" width={[6 / 10]} m={['1rem auto 0 auto', '1rem auto 0 auto', '1rem auto 0 auto']}>
            <Flex wrap={['wrap']} textAlign="center">
              <Box textAlign="center" width={[1]}>
                {(imageArray.length === 1 && <SRLWrapper><GatsbyImage alt="news image" image={imageArray[0].node.childImageSharp.gatsbyImageDataFullWidth} /></SRLWrapper>) || (imageArray.length > 1 && <NukaCarousel imageArray={imageArray} />)}
              </Box>
            </Flex>
          </Box>
        </Box>
      </PageTransition>
    );
  }
}

export const pageQuery = graphql`
  query NewsPostByPath($slug: String!, $relativeDirectory: String!) {
    newsArticleImages: allFile(
      filter: { sourceInstanceName: { eq: "newsArticles" }, relativeDirectory: { eq: $relativeDirectory }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      edges {
        node {
          id
          name
          relativePath
          sourceInstanceName
          childImageSharp {
            gatsbyImageDataFullWidth: gatsbyImageData(layout: CONSTRAINED, height: 500, quality: 90)
          }
        }
      }
    }
    newsCategoryImages: allFile(
      filter: { sourceInstanceName: { eq: "newsArticles" }, name: { eq: "placeholder" }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      group(field: relativeDirectory) {
        field
        fieldValue
        totalCount
        edges {
          node {
            id
            name
            relativePath
            sourceInstanceName
            relativePath
            relativeDirectory
            childImageSharp {
              gatsbyImageDataFullWidth: gatsbyImageData(layout: FULL_WIDTH, quality: 90)
            }
          }
        }
      }
    }
    articleMarkdown: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      fields {
        articleCategory
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
        targeturl
        targeturlname
        targeturltitle
      }
    }
  }
`;

export default NewsTemplate;
